
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "404",
  inheritAttrs: false,
  setup() {
    const router = useRouter();

    const route = (url: string) => {
      router.push(url);
    };

    return {
      route
    };
  }
});
